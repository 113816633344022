export const handleNewLetterNavigation = async (e) => {
    e && e.preventDefault();
    const elementToView = document.getElementById('newsLetterSignUp');
    elementToView.scrollIntoView({
        offsetTop: -300,
        behavior: "smooth",
        block: "center",
        inline: "end",
    });
}
