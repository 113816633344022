import React from "react";
import { AI, IO } from "../../../common/Icons";
import { NavLinks, NavLinksMain } from "../../../common/Data";
import { Link } from "react-router-dom";
import { Accordion, useAccordionButton } from "react-bootstrap";
import {handleNewLetterNavigation} from "./helper";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log()
  );

  return (
    <div className="d-flex text-white m-0 list--style justify-content-between align-items-center border-bottom border-white rounded-0">
      {children}
      <IO.IoIosArrowDropdown
        type="button"
        size={23}
        style={{ color: "white" }}
        onClick={decoratedOnClick}
      />
    </div>
  );
}

const MobileMenu = ({ show, hide, organizationWebLinks }) => {
  const close = () => {
    hide(true);
  };
  return (
    <div className="mobile-menu">
      {<div className={`backdrop ${show ? "backdrop-show" : ""}`} />}
      <div className={`menu ${show && "active"} container`}>
        <div className="d-flex justify-content-between">
          <span>Trip Merchant</span>
          <AI.AiOutlineClose
            className="text-unselectable"
            onClick={() => close()}
          />
        </div>
        <ul className="list--style">
          {organizationWebLinks
            ? NavLinksMain.map((data, index) => (
                  data.anchor ? (
                      <li>
                        {/* eslint-disable-next-line */}
                        <a onClick={(e) => {
                          e.preventDefault(); // Prevents default anchor behavior
                          close();
                          handleNewLetterNavigation(e);
                        }}
                        >
                          {data?.title}
                        </a>
                      </li>
                  ):(<Link to={data.link} key={index} onClick={()=>close()}>
                        <li className="border-bottom border-bottom-1 border-white rounded-0">
                          {data.title}{" "}
                        </li>
                      </Link>)
              ))
              : NavLinks.map((data, index) => (
                  <>
                    {data?.children ? (
                        <>
                          <Accordion className="bg-transparent p-0 m-0 border-0">
                        <Accordion.Item
                          className="bg-transparent p-0 m-0 bg-transparent border-0 "
                          eventKey={index}
                        >
                          <CustomToggle eventKey={index}>
                            <Link to={data.link} key={index}>
                              <li>{data.title} </li>
                            </Link>
                          </CustomToggle>
                          <Accordion.Body className="bg-transparent p-0 m-0 mb-1">
                            {data.children.map((data, index) => (
                              <Link to={data.link} key={index}>
                                <li
                                  className="mx-2 font-xsm text-white border-bottom border-bottom-1 border-white rounded-0"
                                  style={{ lineHeight: "16px" }}
                                >
                                  {data.title}{" "}
                                </li>
                              </Link>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  ) : (
                    <Link to={data.link} key={index}>
                      <li className="border-bottom border-bottom-1 border-white rounded-0">
                        {" "}
                        {data.title}{" "}
                      </li>
                    </Link>
                  )}
                </>
              ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
