import React from "react";
import About from "../../views/About";
import Title from "../../components/Title";
import { Row } from "react-bootstrap";
import { BlogContent } from "../../common/Data";
import Blog from "../../components/Blog";
import { HeroSection } from "../../components/HeroSection";
import WebRoutes from "../WebRoutes";

const WhoWeAre = () => {
  return (
    <>
      <Title title={"Trip Merchant | Who We Are"} />

      <HeroSection
        heading={"Who We Are"}
        title={"Meet The Trip Merchant Team"}
        buttonText={"Contact Us"}
        color="#F25926"
        image={require("../../assets/images/whoweare.jpg")}
        link={WebRoutes.ContactUs}
        bannerClass={" justify-content-center text-center"}
      />
      <About
        heading={"Who We Are"}
        subheading={null}
        detailsArray={[
          "Trip Merchant provides members with exclusive benefits and unique travel experiences worldwide!",
          "Trip Merchant’s main focus is working with Membership-Based Organizations and Employers by providing them with a complimentary branded travel website dedicated to their organization. We partner with these organizations to enhance their member and employee benefit programs.",
          "NEW! We now have a general subscription option available for family and friends of members.",
          "We strive to cultivate strong relationships and negotiate lower rates with a diverse range of travel suppliers. This allows us to cater to various travel preferences, including ocean and river cruises, solo adventures, escorted tours, luxury trips, and more, ensuring that every traveller has the opportunity for an unforgettable journey.",
        ]}
      />
      <div className="d-flex justify-content-center">
        <h2>Meet the Team</h2>
      </div>
      <div className="container who-we-are">
        <Row className="mx-lg-5">
          {BlogContent.map((data, index) => (
            <Blog key={index} data={data} />
          ))}
        </Row>
      </div>
    </>
  );
};

export default WhoWeAre;
