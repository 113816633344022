import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Carousel from "react-multi-carousel";
import { FA } from "../common/Icons";
import "react-multi-carousel/lib/styles.css";
import {
  expandArrayWithPreviousValues,
  strapiUrlApi,
} from "../common/strapiUrl";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const text = `I heard about Trip Merchant through my retired teachers’ association in the Spring of 2019.  They were offering a group departure to Egypt in Feb 2020 that interested me.`;
const getData = async (url, filter) => {
  const response = await fetch(
    `${strapiUrlApi}${url}?populate=*&[filters][Testimonial]=${filter}`
  );
  const data = await response.json();
  return data;
};
const Testimonials = ({ data }) => {
  // console.log(data?.Testimonial, "data");
  useEffect(() => {
    getData("/testimonials", data?.Testimonial).then((res) => {
      // console.log(res?.data, "res/////////////////////////////////////////");
      if (res?.data?.length < 4) {
        setComments(expandArrayWithPreviousValues(res?.data, 5));
      } else {
        setComments(res?.data);
      }
    });
  }, [data?.Testimonial]);
  const [comments, setComments] = useState([]);
  // const array = data?.Comments || [1, 2, 3, 4, 5];
  return (
    <div className="p-4">
      <div className="container pt-4">
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center align-items-center text-center flex-column width-100" >
            <h3 className="mb-4">
              {data?.Heading || "What People Think About Us"}
            </h3>
            <div className="container py-2">
              <Carousel
                responsive={responsive}
                autoPlay={comments.length > 4}
                infinite={true}
                showDots={false}
                arrows={true}
                draggable={true}
              >
                {comments?.map((ele, index) => {
                  return (
                    <Card className="testimonial-cards" key={index}>
                      <div className="testimonial-icon">
                        <div className="icon-wrapper">
                          <FA.FaQuoteRight />
                        </div>
                      </div>
                      <Comment ele={ele} index={index} />
                    </Card>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Comment = ({ ele, index }) => {
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <CardBody className="my-2">
      <div onClick={toggle} className="my-1 mb-2" id={"Tooltip-" + index}>
        <h5>
          {ele?.attributes?.Heading && ele?.attributes?.Heading.length > 50
            ? ele?.attributes?.Heading.substring(0, 48) + "..."
            : ele?.attributes?.Heading}
        </h5>
        {ele?.attributes?.comment
          ? ele?.attributes?.comment.length > 150
            ? ele?.attributes?.comment.substring(0, 145) + "..."
            : ele?.attributes?.comment
          : text.length > 150
          ? text.substring(0, 145) + "..."
          : text}
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{ele?.attributes?.Heading}</ModalHeader>
        <ModalBody>{ele?.attributes?.comment}</ModalBody>
        <ModalFooter>
          <span>
            {ele?.attributes?.By || "TM"}
            {/* (
            {ele?.attributes?.Date || "18-Jul-2023"}) */}
          </span>
        </ModalFooter>
      </Modal>
      {/* <Tooltip
        placement={"right"}
        isOpen={tooltipOpen}
        target={"Tooltip-" + index}
        toggle={toggle}
      >
        {ele?.attributes?.comment}
      </Tooltip> */}

      <span>
        {ele?.attributes?.By || "TM"}
        {/* ({ele?.attributes?.Date || "18-Jul-2023"}) */}
      </span>
    </CardBody>
  );
};

export default Testimonials;
